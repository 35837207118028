import React from "react"
import Layout from "../../components/layout"

export default function TeamGirlsU13() {

    
  return ( 
    <Layout>
    <section className="container">
    <h1>Girls Under 13's</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/girls-u13.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
                <p>Our team ethos is to focus on long term player development based on the 4 corners of the FA’s model, developing a player psychologically, socially, tactically and technically.</p>
                <p>We encourage our players to have a go in all positions throughout the season so they learn the importance of each role within the team and how they need to work together in order to progress. Research has shown that this ethos develops a strong player with an embedded love of football as a game and as a team sport. </p>
                <p>Every team has Managers, Coaches or Assistants who are either FA or UEFA qualified. In addition all staff are CRC checked with at least one First Aid qualified member present at all times.</p>
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 8, DoB 01/09/2009 - 31/08/2010</dd>
                <dt>Manager(s)</dt>
                <dd>Helen Burrough<br/>
                Neil Dodds</dd>
                <dt>Training</dt>
                <dd>Wednesday 5:30-6:30pm on the Astro at AWMC</dd>
                <dt>League</dt>
                <dd>Westriding Girls League</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:family.burrough@gmail.com">family.burrough@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-therefillingstation.png" />
                <div className="card-body">
                    <p className="card-text">The Refilling Station. Specialist food shop</p>
                    <div className="btn-group">
                    <a href="https://www.facebook.com/refillingstationstore/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

